/* footer css start */
@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/Font/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/Font/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-RealBold";
  src: url("../assets/Font/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../assets/Font/Poppins-ExtraBold.ttf");
}

* {
  font-family: "Poppins-Regular";
}

.bg-footer {
  background-color: #3ec5fa;
  padding: 50px 0 30px;
}

.font-bold {
  font-family: "Poppins-Bold";
  
}

.font-realbold {
  font-family: "Poppins-RealBold";
}

.font-exbold {
  font-family: "Poppins-ExtraBold";
}

.footer-heading {
  letter-spacing: 2px;
}

.footer-link a {
  color: #fff;
  line-height: 30px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.5s;
}

.footer-copyrights{
  color: #fff;
  line-height: 10px;
  text-decoration: none;
  font-size: 10px;;
}

.footer-link a:hover {
  color: #ffffff;
  font-weight: 700;
}

.contact-info {
  color: #ffffff;
  font-size: 14px;
}

.footer-social-icon {
  font-size: 15px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  transition: border-radius 1s;
}

.footer-social-icon:hover {
  border-radius: 3px;
}

.facebook {
  background-color: #4e71a8;
  color: #ffffff;
}

.twitter {
  background-color: #55acee;
  color: #ffffff;
}

.google {
  background-color: #d6492f;
  color: #ffffff;
}

.insta {
  background-color: #ca0065;
  color: #ffffff;
}

.footer-alt {
  color: #ffffff;
}

.footer-heading {
  position: relative;
  padding-bottom: 12px;
}

.footer-heading:after {
  content: "";
  width: 25px;
  border-bottom: 1px solid #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  border-bottom: 1px solid #ffffff;
}

.footer-input {
  color: #fff;
  background-color: #fff0;
  border: 1px solid transparent;
  border-bottom: 1px solid #fff;
  border-radius: 0px !important;
  padding: 0.375rem 0rem;
}

.footer-input:focus {
  color: #fff;
  background-color: #fff0;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0);
  border-bottom: 2px solid #fff;
}

.footer-input::placeholder {
  color: rgb(255, 255, 255);
  opacity: 1;
}

.footer-input::-ms-input-placeholder {
  color: #fff;
}

.btn-light {
  color: #fff;
  background-color: #f8f9fa00;
  border-color: #ffffff;
  border-radius: 0px;
  padding: 8px 30px;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

/* footer css end */

/* navbar start */

@media all and (min-width: 992px) {
  .dropdown-menu {
    width: 13rem;
  }

  .mega-submenu {
    left: 100%;
    top: 0;
    min-width: 25rem;
  }

  .ktm-mega-menu {
    position: static;
  }

  .mega-menu {
    left: 0;
    right: 0;
    width: 100%;
  }

  .dropdown-menu li {
    position: relative;
  }

  .dropdown-menu .submenu {
    display: none;
    left: 100%;
    top: 0;
  }

  .dropdown-menu>li:hover>.submenu,
  .dropdown:hover>.dropdown-menu {
    display: block;
  }
}

.home-navbar .navbar-light .navbar-nav .nav-link.active,
.home-navbar .navbar-light .navbar-nav .nav-link:hover,
.home-navbar .navbar-light .navbar-nav .nav-link:focus {
  color: #3ec5fa !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0);
  font-weight: 500;
}

.bg-light-nav-top {
  background-color: #ffffff !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.logo-img {
  width: 150px;
  height: auto;
}

.home-navbar {
  padding: 15px;
}

.contact-btn {
  color: #fff;
  background-color: #3ec5fa;
  border-color: #3ec5fa;
  border-radius: 0px;
  padding: 10px 25px;
  font-weight: 500;
}

.contact-btn:focus+.contact-btn,
.contact-btn:focus,
.contact-btn:hover {
  color: #3ec5fa;
  background-color: white;
  border-color: #3ec5fa;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.active .contact-btn {
  color: #3ec5fa;
  background-color: white;
  border-color: #3ec5fa;
  box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 0%);
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 1%);
}

.tabs .tab {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  cursor: pointer;
  font-size: 18px;
}

/* .active {
  border-bottom: 1px solid #302f2f;
  color: #3ec5fa;
  font-weight: 500;
} */

.tabs .tab.active {
  border-bottom: 1px solid #302f2f;
  color: #3ec5fa;
  font-weight: 500;
}

.mega-menu-inner {
  padding-top: 20px;
  padding-bottom: 20px;
  /* height: 75vh;
  overflow-y: scroll; */
}

.padd-left {
  padding-left: 50px;
}

.cycle-style {
  width: 25px;
  margin-right: 10px;
  height: fit-content;
}

.padd-left p {
  font-size: 12px;
  line-height: 20px;
}

.bg-food-agr {
  padding: 15px 12px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 10px;
}

.bg-food-agr.active {
  background-color: #e9f8fe;
}

.inner-icon {
  width: 30px;
  height: 30px;
}

.bg-star-icon {
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 8px;
  margin: 3px;
  margin-right: 8px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
  color: #1bb8f7;
}

.cursor-pointer:hover {
  color: #01597c;
}

/* navbar end */

.banner-img {
  background-image: url("../assets/images/banner-img.webp");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-heading {
  color: #fff;
  font-size: 59px;
  font-weight: 800;
  text-align: center;
}

.blue-heading {
  color: #3ec5fa;
}

.bg-video-wrap2 video {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bg-video-wrap2 {
  position: relative;
}

.bg-video-wrap2 .home-video-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
.content {
  padding: 30px
} */
.client-content {
  background-color: white;
  padding: 30px 0px;
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto; */
}

/* .trusted {
  padding: 30px 0px;
} */

.aboutbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.client {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 3%;
}

.client>h6,
.client-3>h6 {
  color: #3ec5fa;
}

.arrow {
  background-color: #3ec5fa;
  color: #ffffff;
  padding: 16px 24px;
  margin: 10px;
  width: 72px;
  border-radius: 5px;
}

.client-logo {
  height: auto;
}

.logos {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  overflow-x: auto;
  height: auto;
  align-items: center;
  margin-bottom: 30px;
}

.rightarrow {
  padding: 10px;
}

.cards {
  background-color: #e9f8fe;
  padding: 30px 0px;
}

/* .servicecard {
  display: flex;
  height: 395px;
  width: 285px;
  flex-direction: column;
  padding: 0px 20px;
} */

.cardicon {
  display: flex;
  background-color: #3ec5fa;
  justify-content: center;
  align-items: center;
  border-radius: 30px 30px 0px 0px;
  padding: 21% 0%;
}

.cardicon>i {
  color: white;
  font-size: 86px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: auto;
  border-radius: 0px 0px 30px 30px;
  padding: 10px;
}

.cardContent>p {
  text-align: fixed;
  padding: 10px;
}

.cardContent a>button {
  background-color: #3ec5fa;
  color: #e9f8fe;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  margin-left: 10px;
}

.cardstwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  text-align: center;
  padding: 10px;
}

.cardstwo>div {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
}

.cardstwo>h3 {
  padding: 20px 13px;
}

.works {
  padding: 30px;
  display: flex;
  align-items: center;
}

.cards3 {
  padding: 20px;
}

.cards3>img {
  width: 500px;
}

.card-3-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-3-2>a {
  align-self: flex-end;
}

.works>div>button {
  width: 25%;
  margin: auto;
}

.commentsection {
  background-color: #e9f8fe;
  padding: 30px;
  display: flex;
  gap: 50px;
}

.customervoice {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  margin: 20px;
}

.quote {
  position: absolute;
  display: flex;
  width: 40px;
  height: 40px;
  color: white;
  background-color: #3ec5fa;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: -20px;
}

.cards4 {
  padding-top: 30px;
}

.ecomcard {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.ecomcard>img {
  width: 100%;
}

.ecomcontent {
  padding: 20px 40px;
}

.container>div>div>img {
  height: auto;
  width: 100%;
}

.team {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px;
}

.team>img {}

.team>div {
  justify-content: center;
  height: 96%;
  align-items: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  margin: -1px 9px;
  border-radius: 20px;
}

.team>div>p {
  width: 60%;
}

.carousal-container {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  background-color: #e9f8fe;
  overflow-x: auto;
  height: auto;
  align-items: center;
  margin-bottom: 30px;
}

.comment-1 {
  width: 400px;
  margin: 19px;
}

.profile {
  display: flex;
  gap: 10px;
}

.profile>div>img {
  height: auto;
  width: 56px;
}

.homecsl .owl-carousel .owl-nav {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  position: absolute;
  right: 12px;
  top: -176px;
}

.homecsl .owl-carousel .owl-nav button.owl-prev,
.homecsl .owl-carousel .owl-nav button.owl-next {
  color: white !important;
  background-color: #3ec5fa !important;
  height: 47px;
  width: 47px;
}

.homecsl .owl-carousel .owl-nav button.owl-prev:hover,
.homecsl .owl-carousel .owl-nav button.owl-next:hover {
  color: white !important;
  background-color: #1bb8f7 !important;
}

.homecsl .owl-carousel .owl-nav button.owl-prev,
.homecsl .owl-carousel .owl-nav button.owl-next,
.homecsl .owl-carousel button.owl-dot {
  font: message-box;
  font-size: 30px !important;
}

.homecsl .owl-carousel .owl-item img {
  width: 72%;
}

.carousal1 {
  background-color: #e9f8fe !important;
  padding: 40px 0px;
}

.homecsl .owl-carousel .owl-nav.disabled {
  display: block !important;
}

.aboutbanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: auto;
  height: 296px;
  margin-bottom: 30px;
  margin-top: 97px;
  background-image: url("../assets//images//aboutbanner.webp");
  background-size: cover;
}

.aboutbanner>h4 {
  font-size: 48px;
  font-weight: 700;
}

.aboutbanner>h6 {
  font-size: 24px;
  font-weight: 500;
}

.summary {
  padding: 40px;
  display: flex;
  gap: 30px;
}

.summary>div>img {
  border-radius: 20px;
  height: auto;
  width: 100%;
}

.vision {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}

.sum>div>img {
  width: 800px;
  height: 500px;
}

.sum {
  display: flex;
}

.vision>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vision>div>div:first-child {
  background-color: #e9f8fe;
  padding: 20px;
  border-radius: 20px;
}

.vision>div>img {
  height: 40px;
  width: 40px;
  margin: 20px;
}

/* .vision{
  background-color: #3EC5FA;
} */
.summary>div>h5 {
  color: #3ec5fa;
  text-transform: uppercase;
  font-weight: 800;
}

.summary>div>h1 {
  font-weight: 700;
}

.vision>div>div>h6 {
  color: #3ec5fa;
}

.aboutcontent>h1 {
  padding: 10px 0px;
}

.profilecard>img {
  height: 250px;
  width: auto;
}

.profilecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.aboutprofile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7%;
  align-items: flex-start;
  justify-content: center;
  padding: 21px 0px;
}

/* .leaders,.values{
  padding: 40px;
} */
.leaders>div>h6 {
  color: #3ec5fa;
}

.values>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 8%;
}

.values>div>p {
  width: 60%;
}

.values>div>h1 {
  font-weight: 700;
}

.values>div>h6 {
  background-color: #3ec5fa;
  padding: 5px 10px;
  border-radius: 40px;
  color: white;
}

.card5 {
  width: 250px;
}

.card5>h6 {
  font-weight: 700;
  padding-top: 20px;
}

.valuecards {
  margin: 5% 2%;
}

.card5>div {
  display: flex;
  height: 80px;
  width: 80px;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.offcard {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.card7>div>h5 {
  padding-top: 20px;
  color: #3ec5fa;
}

.card7>div>p {
  color: #b7b7b7;
}

.card7 {
  width: 330px;
}

.office {
  display: flex;
  justify-content: center;
}

.image {
  border-radius: 20px;
}

.align {
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
}

.client-4 {
  padding: 30px 0px;
}

.topcontent {
  position: relative;
}

.sr-content {
  padding: 29px;
  background-color: white;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 100px 0px 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1;
  position: relative;
  margin-top: 3%;
  margin-left: 6%;
  margin-right: -17%;
  width: 44vw;
}

/* .sr-content>h3 {
  font-size: 37px;
} */

/* 
.sr-image>img {
  position: relative;
  right: -314px;
  z-index: -1;
} */

.sr-image {
  position: absolute;
  right: 0;
}

.datacard {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.data {
  margin-top: 12%;
}

.icon {
  color: #3ec5fa;
  background-color: rgba(62, 197, 250, 0.2);
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 12px;
}

.data-3 {
  margin-top: 50px;
  margin-bottom: 10px;
  padding: 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}

.headline {
  margin-top: 8%;
  margin-bottom: 2%;
}

.buttons {
  display: flex;
  align-items: flex-start;
}

.con-btn {
  background-color: #3ec5fa;
  color: white;
  border-radius: 20px;
  border: none;
  padding: 6px 20px;
  border: 1px solid #3ec5fa;
}

.con-btn:hover {
  background-color: white;
  color: #3ec5fa;
  border: 1px solid #3ec5fa;
}

.con-btn-2:hover {
  background-color: #3ec5fa;
  color: white;
}

.con-btn-2 {
  background-color: white;
  color: #3ec5fa;
  border-radius: 20px;
  border-color: #3ec5fa;
  padding: 4px 20px;
}

.serviceCard {
  margin: 10px 0px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: fixed;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.ideacard {
  width: 443px;
  margin-bottom: 33px;
  padding: 10px;
}

.ideacard>div>i {
  color: gainsboro;
  font-size: 60px;
  padding-right: 10px;
}

.ideacard:hover {
  transform: scale(1.1);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 10px;
}

.serviceimg {
  /* position: absolute;
  right: 0; */
}

.serviceimg>img {
  border-radius: 100px 0px 0px 0px;
}

.sidecontent {
  position: relative;
  height: 100%;
  margin: 3% 0%;
}

.serviceimg2>img {
  border-radius: 0px 100px 0px 0px;
}

.sidecontainer>div>div>img {
  height: auto;
  width: 50%;
  top: -70px;
}

.servicecontent {
  margin: 0px 0px 0px 40px;
}

.servicecontent2 {
  margin: 0px 40px 0px 0px;
}

.servicecontent>img {
  height: auto;
  width: 60px;
}

.serviceimg2 {
  /* position: absolute;
  left: 0; */
}

.serviceimg2 img {
  width: 100%
}

.reviewcard>div>i {
  color: gainsboro;
  font-size: 60px;
  padding-right: 10px;
}

.reviewcard {
  display: flex;
  width: 80%;
  align-items: center;
}

.review {
  padding: 20px;
}

.reviewcard:hover {
  transform: scale(1.1);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 10px;
}

.left-radius {
  border-radius: 0px 0px 0px 100px;
}

.content-header {
  text-transform: uppercase;
  color: #3ec5fa;
}

.content-header:before {
  content: "";
  width: 130px;
  height: 5px;
  background: c#3EC5FA;
  position: absolute;
}

.content-head {
  position: relative;
  color: #3ec5fa;
}

.content-head>h5 {
  margin-left: 11%;
  font-weight: bold;
}

.hr-lines {
  text-transform: uppercase;
}

.hr-lines:before {
  display: block;
  content: " ";
  height: 14%;
  width: 10%;
  position: absolute;
  top: 50%;
  left: 0px;
  background: #3ec5fa;
}

.aboutus {
  margin: 7% 0%;
}

.card5>div>.fa-solid {
  font-size: 200%;
  color: #3ec5fa;
}

.card-line {
  position: relative;
  padding-bottom: 12px;
}

.card-line:after {
  content: "";
  width: 53px;
  border-bottom: 3px solid #3ec5fa;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
}

.rating>i {
  color: #3ec5fa;
  padding: 2px;
}

.rating {
  padding: 1% 0%;
}

.headlinel {
  margin: 3% 0%;
}

.purposecard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prt-btn-1 {
  background-color: #3ec5fa;
  color: white;
  border: 2px solid #3ec5fa;
  padding: 9px 28px;
  margin-right: 3%;
  margin-top: 1%;
}

.prt-btn-1:hover {
  background-color: white;
  color: #3ec5fa;
  border: 1px solid #3ec5fa;
}

.prt-btn-2 {
  border: 2px solid #3ec5fa;
  color: #3ec5fa;
  padding: 9px 28px;
  background-color: white;
  margin-top: 1%;
}

.prt-btn-2:hover {
  color: white;
  background-color: #3ec5fa;
}

.prt-csl {
  background-color: #e9f8fe;
  margin-bottom: 2%;
}

.prt-service-card {
  padding: 4%;
}

.prt-service-card>p {
  color: #646464;
}

.lin:before {
  display: block;
  content: " ";
  height: 14%;
  width: 14%;
  position: absolute;
  top: 50%;
  left: 0px;
  background: #3ec5fa;
  z-index: 0;
}

.lin {
  margin-left: 14%;
}

.lean {
  border-right: 1px solid #eff0f6;
}

.wrapper {
  padding: 4%;
  border: 1px solid #eff0f6;
  margin-bottom: 4%;
  border-radius: 20px;
}

.globe>h6 {
  color: #3ec5fa;
  text-align: center;
}

.globe>img {
  height: 50%;
  width: auto;
}

.car-btn {
  color: white;
  background-color: #3ec5fa;
  border: none;
  border-radius: 20px;
  padding: 4px 28px;
}

.car-spn {
  color: #3ec5fa;
}

.car-opty {
  border: 1px solid #c4c4c4;
  padding: 10px;
  border-radius: 14px;
}

.car-bot {
  background-color: rgba(62, 197, 250, 0.5);
  color: white;
  border-radius: 20px;
}

.inline {
  position: relative;
}

.car-btn2 {
  position: absolute;
  top: 8px;
  background-color: #3ec5fa;
  border: none;
  color: white;
  right: 8px;
  width: 34%;
  border-radius: 5px;
  font-size: 13px;
  height: 31px;
}

.car-btn2:hover {
  background-color: white;
  border: 1px solid #3ec5fa;
  color: #3ec5fa;
}

.car-btn2:focus {
  background-color: white;
  border: 1px solid #3ec5fa;
  color: #3ec5fa;
}

.content-blue {
  padding: 20px 10px;
}

.car-csl .owl-carousel .owl-nav {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  top: 0;
}

.car-csl .owl-carousel.owl-drag .owl-item.active.center {
  scale: 1.9;
  z-index: 1;
}

.car-csl .owl-carousel .owl-stage-outer {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.car-csl .owl-carousel.owl-drag .owl-item.active {
  scale: 1.3;
}

.car-csl .owl-carousel .owl-nav.disabled {
  display: block;
}

.car-csl .owl-carousel .owl-nav button.owl-prev,
.car-csl .owl-carousel .owl-nav button.owl-next {
  color: #3ec5fa !important;
  background-color: white !important;
  height: 47px;
  width: 47px;
  border: 1px solid #3ec5fa;
  border-radius: 50%;
  font: message-box;
  font-size: 30px !important;
}

.car-csl .owl-carousel .owl-nav button.owl-prev:focus,
.car-csl .owl-carousel .owl-nav button.owl-next:focus {
  color: white !important;
  background-color: #3ec5fa !important;
  height: 47px;
  width: 47px;
  border: 1px solid #3ec5fa;
  border-radius: 50%;
  font: message-box;
  font-size: 30px !important;
}

.car-csl .owl-carousel .owl-nav button.owl-prev:hover,
.car-csl .owl-carousel .owl-nav button.owl-next:hover {
  color: white !important;
  background-color: #3ec5fa !important;
  height: 47px;
  width: 47px;
  border: 1px solid #3ec5fa;
  border-radius: 50%;
  font: message-box;
  font-size: 30px !important;
}

.bl-head {
  color: white;
  background-color: #3ec5fa;
  border-radius: 20px;
  width: fit-content;
  padding: 7px 16px;
}

.bl-read {
  text-decoration: underline;
  text-underline-offset: 9px;
  color: #302f2f;
  display: flex;
  justify-content: end;
}

.tag>img {
  height: 20px;
}

.tag>p {
  color: #9f9f9f;
}

.tag {
  margin-right: 25px;
}

.bl-para {
  font-size: 12px;
  color: #9f9f9f;
}

.recent {
  display: flex;
  align-items: center;
}

.recent>img {
  margin: 10px 10px;
  width: 26%;
}

.recent>div>p:nth-child(2) {
  font-size: x-small;
  margin: 0;
  color: #9f9f9f;
}

.recent>div>p:first-child {
  font-size: 14px;
  margin: 0;
}

.recentposts {
  margin-left: 14%;
}

.bl-para0 {
  color: #646464;
}

.last-butn {
  border: none;
  padding: 10px 20px;
  border-radius: 10%;
}

.last-butn:focus {
  background-color: #3ec5fa;
  color: white;
}

.last-butn:hover {
  background-color: #3ec5fa;
  color: white;
}

.cnt-para {
  color: #646464;
  font-size: smaller;
}

.cnt-label {
  display: flex;
}

.cnt-label>p {
  background: var(--Grey-100, #f6f7f9);
  margin-right: 17px;
  padding: 1px 12px;
  font-weight: 500;
  border-radius: 5px;
}

textarea {
  resize: none;
}

.form-control {
  border-radius: 10px;
  line-height: 2;
}

.cnt-btn {
  border: none;
  background-color: #3ec5fa;
  color: white;
  padding: 3px 21px;
  border-radius: 10px;
}

.cnt-btn:hover {
  background-color: white;
  color: #3ec5fa;
  border: 1px solid #3ec5fa;
}

.cnt-form {
  border: 1px solid #eff0f6;
  border-radius: 10px;
  padding: 10px 20px;
}

.cnt-contact {
  display: flex;
  margin: 13px 0px 26px 0px;
}

.cnt-contact>div:first-child {
  height: 70px;
  width: 70px;
  background-color: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.cnt-contact>div>i {
  /* background-color: #F6F7F9; */
  /* border-radius: 6px; */
  color: #3ec5fa;
  font-size: x-large;
}

.cnt-contact>div>h5 {
  color: #3ec5fa;
}

.cnt-contact>div>p {
  margin-bottom: 0;
  font-size: small;
}

.cnt-contact>div:nth-child(2) {
  margin: 0px 8px;
}

.cnt-form2 {
  border: 1px solid #eff0f6;
  border-radius: 10px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cnt-logo {
  display: flex;
  justify-content: space-between;
}

.cnt-logo>a {
  height: 50px;
  width: 50px;
  background-color: #3ec5fa;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

/* .cnt-logo>a>i {
  color: white;
}

.cnt-logo>a>i:hover {
  color: #3EC5FA
} */
.cnt-logo>a:hover {
  background-color: white;
  border: 1px solid #3ec5fa;
  color: #3ec5fa;
}

.cnt-service>div>div>i {
  color: #3ec5fa;
  font-size: 40px;
}

.cnt-service>div>div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cnt-service>div>div:first-child {
  border-right: 1px solid #edf0ee;
}

.cnt-service>div>div:nth-child(2) {
  border-right: 1px solid #edf0ee;
}

.cnt-service {
  padding: 30px;
  border: 1px solid #edf0ee;
  border-radius: 20px;
}

.faq-card>p:first-child {
  color: #3ec5fa;
  border-bottom: 1px solid #edf0ee;
}

.faq-card {
  border: 1px solid #edf0ee;
  padding: 20px;
  border-radius: 15px;
}

.faq-card {
  margin: 20px;
}

#getstarted {
  scroll-margin-top: 120px;
}

#productpager {
  scroll-margin-top: -90px;
}

.sr-banner {
  width: 100%;
  margin-top: 6rem;
}

.sr-header {
  position: relative;
}

.sr-header>h3 {
  position: absolute;
  top: 0;
}

.sr-container>div>h1 {
  color: white;
  font-weight: bold;
}

.hm-pr-w {
  width: 75%;
}

.hm-pr-w2 {
  width: 50%;
}

.car-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #3ec5fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-icon:hover {
  background-color: #3ec5fa;
  cursor: pointer;
}

.home-video-content2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topmargin {
  margin-top: 7rem;
}

.neh {
  color: #3ec5fa;
}

.serban {
  background-image: url("../assets//images/servicebanner.webp");
}

.abtban {
  background-image: url("../assets//images/productbanner.webp");
}

.carban {
  background-image: url("../assets//images/careerbanner.webp");
}

.blgban {
  background-image: url("../assets//images/blogbanner.webp");
}

.conban {
  background-image: url("../assets//images/contactbanner.webp");
}

.icon-service {
  width: 70px;
  height: 70px;
}

.sidecontainer {
  margin-bottom: 50px;
}

.serviceimg img {
  height: auto;
  width: 100%;
}


.mvpcontent {
  margin-top: 97px;
}

.mvpbanner {
  position: relative;
}

.mvpcontentx {
  position: absolute;
  top: 0;
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 15%;
  TEXT-ALIGN: center;
}

.mvpcontentx>p:first-child {
  font-size: 5rem;
  font-family: "Poppins-RealBold";
}

.mvpcontentx>p:nth-child(2) {
  font-size: 1.2rem;
}

.number {
  background-color: #3EC5FA;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.sticker {
  display: flex;
  padding-right: 18px;
  margin-top: 5px;
}

.mvpblockimg {
  display: flex;
  justify-content: end;
  height: fit-content;
}

.mvpblockimg1 {
  display: flex;
  justify-content: start;
  height: fit-content;

}

.mvp-img {
  width: 80% !important;
}

.mvplist {
  font-size: 18.5px;
}

.bg-img-video {
  background-image: url("../assets/images/layer.png");
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px 80px 40px;
}

.graytext {
  color: #646464;
}

.mvplist {
  color: #646464;
}

.ourserv {
  width: 20px;
}

.abcd {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
}

.serviceimgnew {
  position: absolute;
  width: 100%;
}

.serviceht {
  height: 70px;
}

.serviceht1 {
  height: 40px;
}

.drop-scroll {
  height: 70vh;
  overflow-y: scroll;
}

/* width */
.drop-scroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.drop-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.drop-scroll::-webkit-scrollbar-thumb {
  background: #3ec5fa;
  border-radius: 10px;
}

/* Handle on hover */
.drop-scroll::-webkit-scrollbar-thumb:hover {
  background: #3ec5fa;
}

.mainservicepage {
  overflow-x: hidden;
}

.validationmsg {
  color: red;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.tnk-head {
  display: flex;
  background-color: #053A58;
  width: 100%;
  justify-content: center;
  padding: 20px;
  border-radius: 16px 16px 0px 0px;
}

.th-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 75px 150px;
  border-radius: 30px;
}

.tnk-btn {
  background-color: #3ec5fa;
  border: none;
  width: 84%;
  margin: 20px;
  height: 52px;
  border-radius: 0px;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.th-cnt>h1 {
  color: #3ec5fa;
  font-size: 4rem;
  margin-top: 50px;
}

.th-cnt>img {
  width: 25%;
  height: auto;
}

.th-cnt>p {
  width: 75%;
  text-align: center;
  font-size: 14px;
}

.tnk-btn:hover {
  color: #3ec5fa;
  background-color: white;
  border: 1px solid #3EC5FA;
}

.ser-over {
  padding: 20px;
  background-color: #e9f8fe;
  border-radius: 10px;
}

.close-btn>i {
  font-size: 2rem;
  color: #3ec5fa;
  cursor: pointer;
}

.close-btn>i:hover {
  background-color: #1bb8f7;
  border-radius: 50%;
  color: white;
}

.close-btn {
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 4px;
}

.groupphoto {
  margin-top: 20px;
  width: 100%;
  height: auto;
}